import { lazyComponent } from 'lib/utils'

const appRoutes = [
	{
		path: '/register',
		element: lazyComponent('components/Accounts/Registration'),
	},
	{
		path: '/register/:invite',
		element: lazyComponent('components/Accounts/Registration'),
	},
	{
		path: '/sign-in/:token',
		element: lazyComponent('components/Accounts/EasySignIn'),
	},
	{
		path: '/',
		element: lazyComponent('components/Home/HomeFrame'),
	},
	{
		path: '/post/:postid',
		element: lazyComponent('components/Post/PostFrame'),
	},
	{
		path: '/discussion/:postid',
		element: lazyComponent('components/Post/PostFrame'),
	},
	{
		path: '/profile',
		element: lazyComponent('components/Profile/ProfileFrame'),
	},
	{
		path: '/profile/:userid',
		element: lazyComponent('components/Profile/ProfileFrame'),
	},
	{
		path: '/saved',
		element: lazyComponent('components/Feed/FeedFrame', { src: 'saved' }),
	},
	{
		path: '/emerge',
		element: lazyComponent('components/Feed/FeedFrame', { src: 'emerge' }),
	},
	{
		path: '/group/:groupid',
		element: lazyComponent('components/Groups/GroupFrame'),
	},
	{
		path: '/courses',
		element: lazyComponent('components/Courses/MyCourses'),
	},
	{
		path: '/course/:courseid',
		element: lazyComponent('components/Courses/ViewCourse'),
	},
	{
		path: '/course/:courseid/:lessonid',
		element: lazyComponent('components/Courses/ViewLesson'),
	},
	{
		path: '/messages',
		element: lazyComponent('components/Messages/MessagesFrame')
	},
	{
		path: '/events',
		element: lazyComponent('components/Events/EventFrame')
	},
	{
		path: '/i-love-art-journal',
		element: lazyComponent('components/App/ILAJournal'),
	},
	{
		path: '*',
		element: lazyComponent('components/App/PageNotFound'),
	},
]

export default appRoutes
