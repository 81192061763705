import AsyncStorage from '@react-native-async-storage/async-storage'
import secureLocalStorage from 'react-secure-storage'
import { isNative } from './utils'

const isProduction = process.env.NODE_ENV === 'production'

// async function
export function put(key, value) {
	if (isProduction && !isNative) {
		return new Promise((resolve, reject) => {
			try {
				secureLocalStorage.setItem(key, value)
				resolve()
			} catch (err) {
				console.error(err)
				reject(err)
			}
		})
	} else {
		return AsyncStorage.setItem(key, JSON.stringify(value))
	}
}

//async function
export function multiPut(multiValue) {
	if (isProduction && !isNative) {
		return new Promise((resolve, reject) => {
			try {
				multiValue.forEach(([key, value]) => {
					secureLocalStorage.setItem(key, value)
				})
				resolve()
			} catch (err) {
				console.error(err)
				reject(err)
			}
		})
	} else {
		const values = multiValue.map(([key, value]) => [key, JSON.stringify(value)])

		return AsyncStorage.multiSet(values)
	}
}

export function has(key) {
	if (isProduction && !isNative) {
		const keys = secureLocalStorage.getAllKeys()

		return keys.includes(key)
	} else {
		; (async () => {
			const keys = await AsyncStorage.getAllKeys()

			return keys.includes(key)
		})()
	}
}

// async function
export function get(key) {
	return new Promise((resolve, reject) => {
		try {
			if (isProduction && !isNative) {
				const value = secureLocalStorage.getItem(key)

				if (value) {
					resolve(value)
				} else {
					resolve(null)
				}
			} else {
				; (async () => {
					const value = await AsyncStorage.getItem(key)

					if (value) {
						try {
							resolve(JSON.parse(value))
						} catch (err) {
							resolve(null)
						}
					} else {
						resolve(null)
					}
				})()
			}
		} catch (err) {
			console.log(err)
			resolve(null)
		}
	})
}

export function remove(key) {
	if (isProduction && !isNative) {
		secureLocalStorage.removeItem(key)
	} else {
		; (async () => {
			await AsyncStorage.removeItem(key)
		})()
	}
}

export function upgradeStorage() {
	; (async () => {
		const regex = /^@secure\./

		if (isProduction && !isNative) {
			const keys = await AsyncStorage.getAllKeys()

			if (!keys.some((k) => regex.test(k))) {
				keys.forEach(async (k) => {
					if (!regex.test(k)) {
						const old = await AsyncStorage.getItem(k)
						secureLocalStorage.setItem(k, JSON.parse(old))
						await AsyncStorage.removeItem(k)
					}
				})
			}
		}
	})()
}